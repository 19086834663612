"use client"

import { AppProgressBar as ProgressBar } from "next-nprogress-bar"

const progressBarStyles = /*css*/`
    #nprogress {
        pointer-events: none;
        z-index: 50;
    }

    #nprogress .bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 105%;
        height: 0.1875rem;
        border-radius: 1rem;
        transform-origin: right;
        --nhn-gradient-cyan: rgb(0 210 255);
        --nhn-gradient-deep-blue: rgb(0 10 255);
        --nhn-gradient-light-blue: rgb(90 110 255);
        --nhn-gradient-purple: rgb(120 55 250);
        background-image: repeating-linear-gradient(to right, var(--nhn-gradient-purple), var(--nhn-gradient-cyan), var(--nhn-gradient-light-blue), var(--nhn-gradient-deep-blue), var(--nhn-gradient-purple));
        animation: progressBarGradient 1.8s linear infinite forwards;
        will-change: transform, background-position, background-size;

        background-position: 0 100%;
        background-size: 200% auto;
    }

    @keyframes progressBarGradient {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: -200% 0%;
        }
    }
`

export default function ProgressBarProvider({
    children
}: {
    children: React.ReactNode
}) {
    return (
        <>
            {children}
            <ProgressBar
                options={{
                    showSpinner: false,
                    trickle: false,
                    speed: 750,
                    fadeSpeed: 150,
                    easing: "cubic-bezier(0.5, 0, 0.6, 1)"
                }}
                style={progressBarStyles}
                startPosition={0.99}
                delay={200}
                stopDelay={10}
                shallowRouting
            />
        </>
    )
}
