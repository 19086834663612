"use client"

import Image from "next/image"
import Link from "next/link"

import logoAndroid from "@/assets/browsers/logo_android@48x48.svg"
import logoChrome from "@/assets/browsers/logo_chrome@48x48.svg"
import logoEdge from "@/assets/browsers/logo_edge@48x48.svg"
import logoFirefox from "@/assets/browsers/logo_firefox@48x48.svg"
import logoIE from "@/assets/browsers/logo_internet_explorer@48x48.svg"
import logoOpera from "@/assets/browsers/logo_opera@48x48.svg"
import logoSafari from "@/assets/browsers/logo_safari@48x48.svg"
import { clsx } from "@/libs/clsx"
import logoLight from "@/logo_dark.svg"

export default function NoScript() {
    const links = [
        {
            title: "Google Chrome",
            alt: "Google Chrome",
            href: "https://www.enablejavascript.io/en/how-to-enable-javascript-on-google-chrome",
            src: logoChrome,
            label: "Chrome"
        },
        {
            title: "Mozilla Firefox",
            alt: "Mozilla Firefox",
            href: "https://www.enablejavascript.io/en/how-to-enable-javascript-on-mozilla-firefox",
            src: logoFirefox,
            label: "Firefox"
        },
        {
            title: "Microsoft Edge",
            alt: "Microsoft Edge",
            href: "https://www.enablejavascript.io/en/how-to-enable-javascript-on-microsoft-edge",
            src: logoEdge,
            label: "Edge"
        },
        {
            title: "Apple Safari",
            alt: "Apple Safari",
            href: "https://www.enablejavascript.io/en/how-to-enable-javascript-on-apple-safari-ipad-iphone-ios",
            src: logoSafari,
            label: "Safari"
        },
        {
            title: "Opera Software",
            alt: "Opera Software",
            href: "https://www.enablejavascript.io/en/how-to-enable-javascript-on-opera",
            src: logoOpera,
            label: "Opera"
        },
        {
            title: "Why using this? What's the price?",
            alt: "Internet Explorer",
            href: "https://www.enablejavascript.io/en/how-to-enable-javascript-on-internet-explorer",
            src: logoIE,
            label: "IE"
        },
        {
            title: "Android Browser",
            alt: "Android Browser",
            href: "https://www.enablejavascript.io/en/how-to-enable-javascript-on-an-android-phonetablet",
            src: logoAndroid,
            label: "Android"
        }
    ]

    return (
        <noscript>
            <div className={clsx(
                "absolute z-49 size-full min-h-[33.75rem] flex flex-col items-center justify-center gap-6 overflow-hidden bg-nhn-background"
            )}>
                <Link href="/" className={clsx(
                    "absolute top-6 h-8 w-auto"
                )}>
                    <Image src={logoLight} alt="hoangnhanne.id.vn" className="size-full" />
                </Link>
                <p className={clsx(
                    "mb-8 text-3.5xl"
                )}>
                    <b>You need to enable <u className="text-nhn-text-javascript">JavaScript</u> to enjoy my site !</b>
                </p>
                <p className={clsx(
                    "font-extralight"
                )}>
                    See how you can enable it with:
                </p>
                <div className={clsx(
                    "z-4 grid grid-cols-noscript min-w-2/5",
                    "children:flex children:flex-col children:items-center children:gap-3 children:text-xs children:font-semibold",
                    "label:w-4/5 label:rounded-md label:px-2 label:text-center label:transition-[color_background-color]",
                    "[&_a:hover_label]:bg-white/80 [&_a:hover_label]:text-black [&_a:hover_label]:duration-0",
                    "img:size-12",
                    "last-of-type:img:absolute last-of-type:img:opacity-50 last-of-type:img:blur-[0.5rem] last-of-type:img:-z-1"
                )}>
                    {links.map((link) => (
                        <Link key={link.href} target="_blank" rel="noopener" title={link.title} href={link.href}>
                            <Image src={link.src} alt={link.alt} />
                            <Image src={link.src} alt="" />
                            <label>
                                {link.label}
                            </label>
                        </Link>
                    ))}
                </div>
                <div className={clsx(
                    "pointer-events-none relative z-3 w-full",
                    "children:fixed children:bottom-0 children:left-0 children:z-0 children:size-full children:translate-y-[30%]"
                )}>
                    <svg
                        width={1440}
                        height={375}
                        viewBox="0 0 1440 375"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <ellipse cx="719.5" cy={465} rx="1161.5" ry={465} fill="url(#paint0_radial_182_124)" />
                        <defs>
                            <radialGradient id="paint0_radial_182_124" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(719.5 465) rotate(90) scale(465 1161.5)">
                                <stop stopColor="#2858FF" />
                                <stop offset={1} stopColor="#00186C" stopOpacity={0} />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
                <i className={clsx(
                    "absolute bottom-6 z-4 size-full h-fit w-auto text-xs text-white font-extralight opacity-50 duration-75 transition-opacity",
                    "hover:opacity-100",
                    "hover:a:underline"
                )}>
                    <b>Source: </b>
                    <Link target="_blank" rel="noopener" href="https://www.enablejavascript.io/en">
                        enablejavascript.io
                    </Link>
                </i>
            </div>
        </noscript>
    )
}
